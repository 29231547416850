$(document).ready(function() {
    //Добавляем класс по клику на кнопку Свернуть меню в сайдбаре
    $('.sidebar__btn').on('click', function(){
        //--Десктоп
        if($(window).width() >= 993){
            $(this).toggleClass('sidebar__btn--rollup');
            $('.sidebar').toggleClass('sidebar--rollup');
            $('.main').toggleClass('main--full');
        } else {
            //--Мобила
            $('.sidebar').removeClass('sidebar--open');
        }

    });

    // Показываем меню по клику на бургер --Мобила
    $('.burger').on('click', function(){
        $('.sidebar').toggleClass('sidebar--open');
    });

    //Активируем меню юзера по ховеру блока profile__user --Десктоп
    $('.profile__user').on('mouseenter', function(){
        $('.profile__menu').addClass('profile__menu--active');
        $(this).addClass('profile__user--active');
    });
    // Клик для мобилы
    $('.profile__user').on('click', function(){
        $('.profile__menu').toggleClass('profile__menu--active');
        $(this).toggleClass('profile__user--active');
    });

    //Снимаем активный класс по уходу мышки из активного меню юзера --Десктоп
    $('.profile__menu').on('mouseleave', function(){
        $(this).removeClass('profile__menu--active');
        $('.profile__user').removeClass('profile__user--active');
    });

    //Активируем меню юзера по ховеру блока profile__user --Мобила
    $('.profile__user-mob').on('click', function(){
        $('.profile__menu').toggleClass('profile__menu--active');
    });

    // Убираем блок превью по клику на видео
    $('.video__play-button').on('click', function(){
        $('.video__substrate').addClass('video__substrate--hide');
    });

    //Формы, добавляем классы при фокусном инпуте
    $('.form__control').focus(function(){
        $(this).closest('.form__item').addClass('form__item--active');
        $(this).closest('.form__item').find('.form__control-name').addClass('form__control-name--active');
    });
    $('.form__control--code').focus(function(){
        $(this).closest('.form__item--code').addClass('form__item--active');
    });

    $('.form__check input:checkbox').on('click', function(){
        if ($(this).is(':checked')) {
            $(this).closest('.form__check').find('.form__check-box').addClass('form__check-box--active');
        }
        else {
            $(this).closest('.form__check').find('.form__check-box').removeClass('form__check-box--active');
        }
    });


    // Показываем весь контент в карточке Вопрос №2 по клику на копку Подробнее
        $('.card__header-link--more').on('click', function () {
            $(this).toggleClass('card__header-link--more-open');
            $(this).closest('.card__header-subtitle').find('.card__header-subtitle--hide').toggleClass('card__header-subtitle--open');

            if ($(this).text() === 'Подробнее') {
                $(this).text('Свернуть')
            } else {
                $(this).text('Подробнее')
            }
        });
    //Меняем текст по клику

    $('.card__header-link--more').text('Подробнее');

    //
    //Активируем меню уведомлений по ховеру блока notify--notice
    $('.notify-notice').on('mouseenter', function(){
        $('.profile__menu-notice').addClass('profile__menu-notice--active');
        $(this).addClass('notify-notice--active');
    });
    // Клик для мобилы
    $('.notify-notice').on('click', function(){
        $('.profile__menu-notice').toggleClass('profile__menu-notice--active');
        $(this).toggleClass('notify-notice--active');
    });

    //Снимаем активный класс по уходу мышки из активного меню юзера --Десктоп
    $('.profile__menu-notice').on('mouseleave', function(){
        $(this).removeClass('profile__menu-notice--active');
        $('.notify-notice').removeClass('notify-notice--active');
    });

    //Интерактивный tooltip //по ховеру информационное окно остается на месте
    //Активируем меню юзера по ховеру блока profile__user --Десктоп
    $('.tooltip-inter').on('mouseenter', function(){
        $('.tooltip-inter__text').addClass('tooltip-inter__text--active');
        $(this).addClass('tooltip-inter--active');
    });
    // Клик для мобилы
    $('.tooltip-inter').on('click', function(){
        $('.tooltip-inter__text').toggleClass('tooltip-inter__text--active');
        $(this).toggleClass('tooltip-inter--active');
    });

    //Снимаем активный класс по уходу мышки из активного меню юзера --Десктоп
    $('.tooltip-inter__text').on('mouseleave', function(){
        $(this).removeClass('tooltip-inter__text--active');
        $('.tooltip-inter').removeClass('tooltip-inter--active');
    });

    // Клик для мобилы Мастер-кабинет Таблица тотал.
    $('.master-cabinet-table__col.master-cabinet-table__col--intotal').on('click', function(){
        $(this).parent().toggleClass('active');
        $(this).toggleClass('active');
    });

    //Скролл договора
    let contractBlock = $('.modal-body__contract');

    if (contractBlock.length > 0) {

        contractBlock.scroll(function () {
            let st = $(this).scrollTop(),
                scrollPos = 0;

            if (st > 150) {
                if (st > scrollPos) {
                    $(this).addClass('modal-body__contract--scroll-down');
                }
                scrollPos = st;

            } else {
                $(this).removeClass('modal-body__contract--scroll-down');
            }
        });
    };
});

jQuery(document).on('click', '.policyForm:checkbox', function(){
    let isChecked = $(this).is(':checked');
    $(this).closest('.form').find('.form__group').toggleClass('d-none', isChecked);
});

//Скрипт аккордеона
let accordions = document.getElementsByClassName("accordion__header");

for (let i = 0; i < accordions.length; i++) {
    accordions[i].onclick = function() {
        this.classList.toggle('accordion__header--open');

        let content = this.nextElementSibling;
        if (content.style.maxHeight) {
            // accordion is currently open, so close it
            content.style.maxHeight = null;
            content.style.overflow = 'hidden';
        } else {
            // accordion is currently closed, so open it
            content.style.maxHeight = content.scrollHeight + "px";
            setTimeout(function () {
                content.style.overflow = 'inherit';
            }, 500);
        }
    }
}

let accordionsPrev = document.getElementsByClassName("accordion__header__prev");


{/* <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 6L6 1L11 6" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1.32056L6 6.32056L11 1.32056" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}



for (let i = 0; i < accordionsPrev.length; i++) {
    accordionsPrev[i].onclick = function() {
        this.classList.toggle('accordion__header--open');
        console.dir(this.children[0].children[1].children[0].attributes[0].value)
        let content = this.previousElementSibling;
        if (content.style.maxHeight) {
            // accordion is currently open, so close it
            content.style.maxHeight = null;
            content.style.overflow = 'hidden';
            this.children[0].children[0].innerHTML = 'Подробнее';
            this.children[0].children[1].children[0].attributes[0].value = "M1 1.32056L6 6.32056L11 1.32056"
        } else {
            // accordion is currently closed, so open it
            content.style.maxHeight = content.scrollHeight + "px";
            this.children[0].children[0].innerHTML = 'Скрыть';
            this.children[0].children[1].children[0].attributes[0].value = "M1 6L6 1L11 6"
            setTimeout(function () {
                content.style.overflow = 'inherit';
            }, 500);
        }
    }
}

$(function() {
    $(document).ready(function() {
        let $textarea = '#subject',
            $counter = '#counter';

        $($textarea).on('blur, keyup', function() {
            let $max = 300, // Максимальное кол-во символов
                $val = $(this).val();

            $(this).attr('maxlength', $max); // maxlength=""
            console.dir($val);
            if( $val.length = 0 ) {
                $($counter).html(300);
            } else {
                if( $max < parseInt( $val.length ) ) {
                    $this.val( $val.substring(300, $max) );
                }
                $($counter).html( 300 - $(this).val().length );
            }
        });
    });
});


//Аккордеон для Портфеля
//Скрипт аккордеона
let accordionsItems = document.getElementsByClassName("accordion-issuer__item");

for (let i = 0; i < accordionsItems.length; i++) {

    let header = document.getElementsByClassName("accordion-issuer__item-header");

    for (let i = 0; i < header.length; i++) {
        header[i].onclick = function() {
            this.classList.toggle('accordion-issuer__item-header--open');
            this.parentNode.classList.toggle('accordion-issuer__item--active');

            let content = this.nextElementSibling;
            if (content.style.maxHeight) {
                // accordion is currently open, so close it
                content.style.maxHeight = null;
                content.style.overflow = 'hidden';
            } else {
                // accordion is currently closed, so open it
                content.style.maxHeight = content.scrollHeight + "px";
                setTimeout(function () {
                    content.style.overflow = 'inherit';
                }, 500);
            }
        }
    }
}

$(document).ready(function() {
    //Активируем форму фильтра при нажатии на кнопку button-filter в мобиле // начиная со страниц Журнал операции
    let buttonFilter = $('.button-filter');

    buttonFilter.each(function () {
        $(this).on('click', function(){
            $(this).toggleClass('button-filter--open');
            $('.form-log').toggleClass('form-log--active');
        });
    });

    //Мини-аккордеон в мобиле для блоков card--lk-open в разделе Открытие лицевого счета для физического лица
    $('.card--lk-open .card-lk__inner').on('click', function(){
        $(this).toggleClass('card-lk__inner--active');
        $(this).closest('.card-lk').find('.card-lk__body').toggleClass('card-lk__body--active');
    });
});

//Скрипт табов для страниц Общеее собрание акционеров (3.0)
//Скрипт табов для страниц Общеее собрание акционеров (3.0)
let tabsAll = document.querySelectorAll(".meeting-sharer__tabs");

for (let i = 0; i < tabsAll.length; i++) {
    let tabNavs = tabsAll[i].querySelectorAll(".nav-tabs-sharer__item"),
        tabPanes = tabsAll[i].querySelectorAll(".tab-content-sharer__pane");

    for (let i = 0; i < tabNavs.length; i++) {

        tabNavs[i].addEventListener("click", function(e){
            e.preventDefault();
            let activeTabAttr = e.target.getAttribute("data-tab");

            for (let j = 0; j < tabNavs.length; j++) {
                let contentAttr = tabPanes[j].getAttribute("data-tab-content");

                if (activeTabAttr === contentAttr) {
                    tabNavs[j].classList.add("nav-tabs-sharer__item--active");
                    tabPanes[j].classList.add("tab-content-sharer__pane--active");
                } else {
                    tabNavs[j].classList.remove("nav-tabs-sharer__item--active");
                    tabPanes[j].classList.remove("tab-content-sharer__pane--active");
                }
            }
        });
    }
}

let firstTabsAll = document.querySelectorAll(".first-tabs");

for (let i = 0; i < firstTabsAll.length; i++) {
    let firstTabNavs = firstTabsAll[i].querySelectorAll(".first-tabs-nav__item"),
        firstTabPanes = firstTabsAll[i].querySelectorAll(".first-tabs-content__pane");

    for (let i = 0; i < firstTabNavs.length; i++) {

        firstTabNavs[i].addEventListener("click", function(e){
            e.preventDefault();
            let firstActiveTabAttr = e.target.getAttribute("data-tab");

            for (let j = 0; j < firstTabNavs.length; j++) {
                let firstContentAttr = firstTabPanes[j].getAttribute("data-tab-content");

                if (firstActiveTabAttr === firstContentAttr) {
                    firstTabNavs[j].classList.add("first-tabs-nav__item--active");
                    firstTabPanes[j].classList.add("first-tabs-content__pane--active");
                } else {
                    firstTabNavs[j].classList.remove("first-tabs-nav__item--active");
                    firstTabPanes[j].classList.remove("first-tabs-content__pane--active");
                }
            }
        });
    }
}

//Вычислаем высоту текстового блока в карточках новостей и добавляем класс если блок выше 100пх. Экраны 5
let cardNewsBlockText = document.querySelectorAll(".card__text-news");

for (let i = 0; i < cardNewsBlockText.length; i++) {

    let blockHeight = cardNewsBlockText[i].clientHeight;
    if (blockHeight >= 100) {
        cardNewsBlockText[i].classList.add("card__text-news--hidden");
    } else {
        cardNewsBlockText[i].classList.remove("card__text-news--hidden");
    }
}

//добавляем и убираем из избранного
$('.favorite').on('click', function(event){    
    let massive = event.currentTarget.classList.value
    console.log();
    if (massive.includes('favorite_red')) {
        event.currentTarget.classList.remove('favorite_red');
    } else {
        event.currentTarget.classList.add('favorite_red');
    }
});


$('select-service').select2({
    placeholder: {
      id: '-1', // the value of the option
      text: 'Например: ПАО Сбербанк',
      color: '#6F7474'
    }
  });


//Вычислаем высоту текстового блока в карточках новостей и добавляем класс если блок выше 100пх. Экраны 5
let buttonsChange = document.querySelectorAll(".btn-tooltip");

for (let i = 0; i < buttonsChange.length; i++) {
  buttonsChange[i].addEventListener("click", function(e) {
    e.preventDefault();
    let attrItem = this.getAttribute('id');

    let modals = document.querySelectorAll(".modal");

    for (let i = 0; i < modals.length; i++) {
      let attrModal = modals[i].getAttribute('data-id');
      console.log(attrModal)
      if (attrItem === attrModal) {
        modals[i].classList.add('modal--open')
      }
    }
  })
}

let buttonModalClose = document.querySelectorAll(".modal-header__close");
for (let i = 0; i < buttonModalClose.length; i++) {
  buttonModalClose[i].addEventListener("click", function(e) {
    e.preventDefault();
    this.closest('.modal').classList.remove('modal--open');
  })
}
